import type { FromModel, ModelDefinition } from "@/__main__/data-model.mjs";
import { Optional, Union } from "@/__main__/data-model.mjs";
import { FORTNITE_QUEUES_BE } from "@/game-fortnite/constants/queues.mjs";
import { ItemRarityEnum } from "@/game-fortnite/types/ItemRarity.mjs";

export const MatchMutation = {
  gameId: String,
  gameMode: Union(FORTNITE_QUEUES_BE),
  isRanked: Boolean,
  isZeroBuild: Boolean,
  islandCode: String,
  kills: [
    {
      killedId: String,
      killerId: String,
    },
  ],
  localPlayer: {
    partyOwnerId: String,
    accountId: String,
    accuracy: Number,
    aliveTime: Number,
    anonymous: Boolean,
    assists: Number,
    battlesResults: [
      {
        kills: Number,
        placement: Number,
      },
    ],
    bot: Boolean,
    damageDealt: Number,
    damageTaken: Number,
    damageToStructures: Number,
    distanceTraveled: Number,
    downs: Number,
    headshotsPercent: Number,
    inventory: [
      {
        key: String,
        name: String,
        quantity: Number,
        rarity: Union(Object.values(ItemRarityEnum)),
      },
    ],
    kills: Number,
    materials: {
      metal: {
        gathered: Number,
        used: Number,
      },
      stone: {
        gathered: Number,
        used: Number,
      },
      wood: {
        gathered: Number,
        used: Number,
      },
    },
    materialsGathered: Number,
    materialsUsed: Number,
    name: String,
    placement: Number,
    revives: Number,
    team: Number,
    teammates: [String],
    totalHeadshots: Number,
    totalHits: Number,
    totalShots: Number,
    weapons: [
      {
        criticalDamageDoneToPlayers: Number,
        damageDoneToPlayers: Number,
        damageDoneToStructures: Number,
        headshots: Number,
        healingAmount: Number,
        hits: Number,
        hitsToStructures: Number,
        id: String,
        kills: Number,
        totalShots: Number,
      },
    ],
  },
  matchPlayers: [
    {
      accountId: String,
      aliveTime: Number,
      anonymous: Boolean,
      battlesResults: [
        {
          kills: Number,
          placement: Number,
        },
      ],
      bot: Boolean,
      downs: Number,
      kills: Number,
      name: String,
      placement: Number,
      team: Number,
      teammates: [String],
      partyOwnerId: String,
    },
  ],
  startedAt: Date,
  gameVersion: Optional(String),
} satisfies ModelDefinition;

export type MatchMutationT = FromModel<typeof MatchMutation>;
